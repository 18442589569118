<template>
  <section class="carousel-section">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <router-link to="/join">
            <img class="d-block w-100 carousel-image" loading="lazy" src="img/carousel/powerbetSignUp.jpeg">
          </router-link>
        </div>
        <div class="carousel-item">
          <a href="/app/powerBet.apk">
            <img class="d-block w-100 carousel-image" loading="lazy" src="img/carousel/powerbetApp.jpeg">
          </a>
        </div>
        <!-- <div class="carousel-item">
          <img class="d-block w-100 carousel-image" loading="lazy" src="img/carousel/free-spin.jpeg">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100 carousel-image" loading="lazy" src="img/carousel/freespin.png">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100 carousel-image" loading="lazy" src="img/carousel/signup.jpeg">
        </div> -->
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slider",
}
</script>

<style scoped>
.carousel-image {
  width: 100%;
  object-fit: cover;
}
</style>